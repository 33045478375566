import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/admin/Login/Login';
import Dashboard from './components/admin/Dashboard/Dashboard';
import Profile from './components/admin/Profile/Profile';
import ForgotPass from './components/admin/Login/ForgotPass';
import ShowUserList from './components/admin/User List/UserList';
import ShowDiversList from './components/admin/Divers List/DiversList';
import DriversDetails from './components/admin/Divers List/components/DriversDetails';
import UserDetails from './components/admin/User List/components/UserDetails';
import ShowJobsList from './components/admin/JobsList/JobsList';
import JobsDetails from './components/admin/JobsList/components/JobsDetails';
import CmsManagement from "./components/admin/CMSmanagement/CmsManagement";
import ShowClaimRequestList from "./components/admin/claimRequestList/ClaimRequestList";
import ShowPayoutRequestList from "./components/admin/PayoutRequestList/PayoutRequestList";

function App() {
  return (
    <Routes>
      <Route path="/teleport-admin" exact element={<Login />} />
      <Route path="*" exact element={<Login />} />
      <Route path="/forgot-password" exact element={<ForgotPass />} />
      <Route path="/admin-profile" exact element={<Profile />} />
      <Route path="/dashboard" exact element={<Dashboard />} />
      <Route path="/users-list" exact element={<ShowUserList />} />
      <Route path="/users-details" exact element={<UserDetails />} />
      <Route path="/drivers-list" exact element={<ShowDiversList />} />
      <Route path="/drivers-details" exact element={<DriversDetails />} />
      <Route path="/jobs-list" exact element={<ShowJobsList />} />
      <Route path="/jobs-details" exact element={<JobsDetails />} />

      <Route path="/claim-request-list" exact element={<ShowClaimRequestList />} />
      <Route path="/payout-request-list" exact element={<ShowPayoutRequestList />} />

      <Route path="/cms-management" exact element={<CmsManagement />} />

      <Route path="/" element={<Navigate to="/teleport-admin" />} />
    </Routes>
  );
}

export default App;
