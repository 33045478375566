import React, { useEffect } from 'react';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ColDetail from '../../general/ColDetail';
import ShowImage from '../../general/ShowImage';

export default function UserDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  var data = location.state;
  console.log('~~~~~~~~~~', data);

  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={'/users-list'}>Users List</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Users Details
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={() => navigate('/users-list')}
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                marginTop: '7px',
                marginRight: '10px',
              }}
            ></i>
            Users Details
          </h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
              marginTop: '10px',
            }}
          >
            <div className="row RestName p-5" style={{ borderRadius: '20px' }}>
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Details of {data?.first_name}
                </span>
              </div>
            </div>
            <br />

            <div className="row">
              <ColDetail title="First Name" data={data?.first_name} />
              <ColDetail title="Last Name" data={data?.last_name} />
              <ColDetail title="Phone No" data={data?.phone_number} />
              <ColDetail title="Email" data={data?.email} />
              <ColDetail title="Birth Date" data={data?.birthdate} />
              <ColDetail
                title="Gender"
                data={data?.gender?.toLowerCase() === 'm' ? 'Male' : 'Female'}
              />
              <ColDetail title="Address" data={data?.address} />
              <ColDetail title="other Address" data={data?.address2} />
              <ColDetail title="City" data={data?.city} />
              <ColDetail title="State " data={data?.state} />
              <ColDetail title="Zip Code " data={data?.zipcode} />
              <ColDetail title="Country " data={data?.country} />
              <ColDetail title="Country Code " data={data?.country_code} />
            </div>
            <div className="row">
              <ShowImage
                title="User Image"
                data={data?.image}
                url={data?.image}
                alt="UserImage"
                dummyUrl={'/assets/img/icon/customer.png'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
