import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";

function ForgotPass() {

  const location = useLocation();
  const navigate = useNavigate();
  const [email, setemail] = useState(location?.state ? location?.state : null);
  const [error, setError] = useState();
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!email) {
      isValid = false;
      errors["email_err"] = "Please enter email";
    }

    setError(errors);
    return isValid;
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);

      const myurl = "http://localhost:3000/api/admin/forgot-password";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("email", email);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            toast.success("!..New Password send successfully to your Email");
            navigate(`/login`);
          } else {
            setDisable(false);
            toast.error("!...Your email is wrong");
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          toast.error("!...Something went wrong");
          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    if (localStorage.getItem("DM_Admin_ID") != null) {
      toast.success(
        `Already login as ${localStorage.getItem("DM_Admin_NAME")}`
      );
      navigate("/dashboard");
    }
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>Teleport</b>
              <small>Forgot Password </small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={submitHandler}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg ml-0"
                  placeholder="Email Address"
                  name="email"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                />
                <div className="text-danger">{error?.email_err}</div>
              </div>
              <div className="form-group m-b-20">
                <NavLink to={"/chef-event-management-admin"}>
                  Login Page
                </NavLink>
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  className="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Send Email"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPass;
